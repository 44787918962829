import React, { useEffect } from 'react'
import "./aboutBetterFuture.css"
import Aos from "aos";
import "aos/dist/aos.css";

export default function AboutBetterFuture() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  
  return (
    <div>
      <div className="betterFuture-layout-364">
            <div className="betterFuture-section-title">
              <div className="betterFuture-content6">
                <div className="betterFuture-heading" data-aos="fade-down" data-aos-duration="700">
                  Building a Better Future
                </div>
              </div>
            </div>
            <div className="betterFuture-container3">
              <div className="betterFuture-row">
                <div className="betterFuture-card" data-aos="fade-right" data-aos-duration="700">
                  <div className="betterFuture-content7">
                    <div className="betterFuture-content-top">
                      <div className="betterFuture-content-top">
                        <div className="betterFuture-date">Our Vision</div>
                        <div className="betterFuture-text4">
                          To be the most respected and admired corporate for the
                          positive impact we make on society by nurturing the
                          lives of those we touch.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="betterFuture-card" data-aos="fade-left" data-aos-duration="700">
                  <div className="betterFuture-content7">
                    <div className="betterFuture-content-top">
                      <div className="betterFuture-content-top">
                        <div className="betterFuture-date">Our Mission</div>
                        <div className="betterFuture-text4">
                          Harnessing science and modern technology, we will
                          provide solutions of superior quality which are
                          efficient and safe. We will build rewarding and
                          lasting relationships with our stakeholders and be a
                          significant entity in every sector we operate.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
