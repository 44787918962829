import React, { useEffect, useState } from 'react';
import './aboutHistory.css';
import { Chrono } from "react-chrono";
// import AboutHistoryImage from "../../images/placeholder-image2@2x.png"
import Aos from "aos";
import "aos/dist/aos.css";

const AboutHistory = () => {

  const [isClient, setIsClient] = useState(false);
  const [cardWidth, setCardWidth] = useState(450);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setCardWidth(250);
      } else if (window.innerWidth <= 768) {
        setCardWidth(350);
      } else {
        setCardWidth(450);
      }
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsClient(true); 
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  const items = [
    {
      title: "1964",
      cardTitle: <div className='about-hitory-cardTitle'>1964</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        Our story begins in 1964, with Chemical Industries (Colombo) Limited, 
        now known as CIC Holdings being incorporated, and
        listed in the Colombo Brokers Association.
      </div>,
      // media: {
      //   type: "IMAGE",
      //   source: {
      //     url: AboutHistoryImage
      //   }
      // }
    },
    {
      title: "1968-1974",
      cardTitle: <div className='about-hitory-cardTitle'>1968-1974</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        The year 1968 saw CIC commence Operations of the Ratmalana Factory with the Agrochemicals Plant, and the
        formation of Chemanex PLC in 1974.
      </div>,
    },
    {
      title: "1998",
      cardTitle: <div className='about-hitory-cardTitle'>1998</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        Lease of Hingurakgoda Farm (1300 acres).
      </div>,
    },
    {
      title: "2000",
      cardTitle: <div className='about-hitory-cardTitle'>2000</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        In the 1990s CIC expanded its operations by incorporating CISCO Speciality Packaging (Pvt.) Ltd. our PET Bottles
        manufacturing business in 1991 and also incorporating CIC Fertilizers (Pvt.) Ltd (now CIC Agri Businesses (Pvt.) Ltd) upon
        purchasing the Fertiliser Business from British American Tobacco in 1992.
        In 1993, CIC invested in CIAL- our gateway to produce writing instruments, and also proudly shifted the Company’s Head
        Office to our own premises at 199, Kew Road, Colombo 2. 1995 saw the incorporation of CIC Paints (Pvt) Ltd, now
        known as Akzo Nobel Paints Lanka (Pvt.) Ltd.
      </div>,
    },
    {
      title: "2001",
      cardTitle: <div className='about-hitory-cardTitle'>2001</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        Lease of Pelwehera Seed Farm (650 acres).
      </div>,
    },
    {
      title: "2002",
      cardTitle: <div className='about-hitory-cardTitle'>2002</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        Purchase of Nutrina (Pvt) Ltd from Cargills Inc. USA and incorporation of CIC Feeds (Pvt) Ltd.
      </div>,
    },
    {
      title: "2003",
      cardTitle: <div className='about-hitory-cardTitle'>2003</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        Investment in Link Natural Products (Pvt) Ltd.
      </div>,
    },
    {
      title: "2010",
      cardTitle: <div className='about-hitory-cardTitle'>2010</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        In 2010, CIC invested in Chemcel (Pvt.) Ltd and shifted the
        Writing Instruments factory to our own premises in Piliyandala.
      </div>,
    },
    {
      title: "2011",
      cardTitle: <div className='about-hitory-cardTitle'>2011</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        The year 2011 witnessed a very significant change with the change of the Company name from Chemical Industries
        (Colombo) PLC to CIC Holdings PLC.
      </div>,
    },
    {
      title: "2012",
      cardTitle: <div className='about-hitory-cardTitle'>2012</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        Expanding the Company’s presence in the healthcare sector, CIC Invested in Ceylinco Pharmaceuticals Limited (now
        known as CIC Lifesciences Limited) in 2012. Incorporation of CIC Dairies
      </div>,
    },
    {
      title: "2014",
      cardTitle: <div className='about-hitory-cardTitle'>2014</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        The year 2014 saw the Company go through a complete organisational restructure and the shifting of the Crop
        Protection Manufacturing Facility from Ratmalana to Godagama.
      </div>,
    },
    {
      title: "2016",
      cardTitle: <div className='about-hitory-cardTitle'>2016</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        In 2016 the Company incorporated CIC Grains (Private) Ltd. to process inputs for the feed industry and expand its
        presence in that sector. The PVA factory was shifted from Ratmalana to Godagama during the same year.
      </div>,
    },
    {
      title: "2017",
      cardTitle: <div className='about-hitory-cardTitle'>2017</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        Purchase of Unipower (Private) Limited
      </div>,
    },
    {
      title: "2021",
      cardTitle: <div className='about-hitory-cardTitle'>2021</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        Purchase of Bio Technologies Ltd
      </div>,
    },
    {
      title: "2022",
      cardTitle: <div className='about-hitory-cardTitle'>2022</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        Acquisition of Asiavet Lifesence (Pvt) Limited
      </div>,
    },
    {
      title: "2023",
      cardTitle: <div className='about-hitory-cardTitle'>2023</div>,
      cardSubtitle: <div className='about-hitory-cardSubtitle'></div>,
      cardDetailedText: 
      <div className='about-hitory-cardDetailedText'>
        John Keells Holdings (JKH) became an associate of CIC Holdings PLC
      </div>,
    },
    
  ];

  return (
    <div className='ourHistory-timeline'>
      <div style={{ width: "100%", height: "90vh" }}>
        <div className='ourHistory-timeline-heading' data-aos="fade-right" data-aos-duration="500">
          Our History
        </div>
        {isClient && (
          <Chrono
            items={items}
            slideShow
            slideItemDuration={4500}
            slideShowType="slide_in"
            mode="HORIZONTAL"
            showAllCardsHorizontal
            // cardWidth={450}
            cardWidth={cardWidth}
            cardHeight={300}
            contentDetailsHeight={100}
            buttonTexts={{
              first: 'Jump to First',
              last: 'Jump to Last',
              next: 'Next',
              previous: 'Previous',
            }}
            fontSizes={{
              // cardSubtitle: '0.85rem',
              // cardText: '0.8rem',
              // cardTitle: '46px',
              title: '24px',
            }}
            theme={{
              primary: '#7b7979',
              secondary: 'rgb(0 25 76 / 82%)',
              cardBgColor: '#00194c',
              titleColor: '#00194c',
              titleColorActive: '#fff',
            }}
          ></Chrono>
        )}

      </div>
    </div>
  );
};

export default AboutHistory;
