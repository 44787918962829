import React from 'react'
import "./aboutMainBanner.css"
// import SustainImage from "../../images/aboutMainBanner.png"
import SustainVideo from "../../images/aboutMainBanner_animation.mp4"

export default function AboutMainBanner() {
  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          {/* <img className="aboutMain-home-image" alt="" src={SustainImage} /> */}
          <video 
            className="aboutMain-home-image" 
            src={SustainVideo}
            autoPlay
            loop
            muted 
          />
          {/* <div class="aboutMainBannerparallax"></div> */}
          <div className="aboutMain-image-text-box">
            <div className="aboutMain-image-background-text">
              <h1 className="aboutMain-image-topic">
                About Us
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
