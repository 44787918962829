import React, { useEffect } from 'react'
import "./aboutValues.css"
import AboutVector1 from "../../images/vector.svg"
import AboutVector2 from "../../images/vector7.svg"
import AboutVector3 from "../../images/vector10.svg"
import AboutVector4 from "../../images/group-23.svg"
import AboutVector5 from "../../images/vector17.svg"
import AboutVector6 from "../../images/run-x2c--rush-x2c--runner-x2c--start-x2c--quick-start-x2c--speed.svg"
import Aos from "aos";
import "aos/dist/aos.css";

export default function AboutValues() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  return (
    <div>
      <div className="aboutValue-layout-228">
        <div className="aboutValue-short-heading-here1" data-aos="fade-down" data-aos-duration="500">
          Our Values
        </div>
            <div className="aboutValue-content13">
              <div className="aboutValue-column5" data-aos="fade-up" data-aos-duration="700">
                <div className="aboutValue-section-title1">
                <div className="aboutValue-icon-relume2" />
                  <img className="aboutValue-vector-icon5" alt="" src={AboutVector1} />
                  <div className="aboutValue-section-title1">
                    <div className="aboutValue-heading5">{`Ownership & Accountability`}</div>
                    <div className="aboutValue-text7">
                      This is my business and I take responsibility for my
                      promises and actions.
                    </div>
                  </div>
                </div>
              </div>
              <div className="aboutValue-column5" data-aos="fade-down" data-aos-duration="700">
                <div className="aboutValue-section-title1">
                    <div className="aboutValue-icon-relume2" />
                  <img className="aboutValue-vector-icon12" alt="" src={AboutVector2} />
                  <div className="aboutValue-section-title1">
                    <div className="aboutValue-heading5">
                      <p className="aboutValue-the-board-plays">{`Teamwork & Trust`}</p>
                    </div>
                    <div className="aboutValue-text7">
                      We rely on each other to unleash the power of working
                      together.
                    </div>
                  </div>
                </div>
              </div>
              <div className="aboutValue-column5" data-aos="fade-up" data-aos-duration="700">
                <div className="aboutValue-section-title1">
                <div className="aboutValue-icon-relume2" />
                  <img className="aboutValue-vector-icon15" alt="" src={AboutVector3} />
                  <div className="aboutValue-section-title1">
                    <div className="aboutValue-heading5">
                      <p className="aboutValue-the-board-plays">{`Integrity & Respect`}</p>
                    </div>
                    <div className="aboutValue-text7">
                      Honesty and truth are paramount; we respect the law and
                      each other.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="aboutValue-content13">
              <div className="aboutValue-column5" data-aos="fade-up" data-aos-duration="700">
                <div className="aboutValue-section-title1">
                <div className="aboutValue-icon-relume2" />
                  <img className="aboutValue-group-icon" alt="" src={AboutVector4} />
                  <div className="aboutValue-section-title1">
                    <div className="aboutValue-heading5">{`Entrepreneurial & Innovative`}</div>
                    <div className="aboutValue-text7">
                      We will constantly challenge boundaries seeking new
                      horizons.
                    </div>
                  </div>
                </div>
              </div>
              <div className="aboutValue-column5" data-aos="fade-down" data-aos-duration="700">
                <div className="aboutValue-section-title1">
                <div className="aboutValue-icon-relume2" />
                  <img className="aboutValue-vector-icon22" alt="" src={AboutVector5} />
                  <div className="aboutValue-section-title1">
                    <div className="aboutValue-heading5">
                      <p className="aboutValue-the-board-plays">{`Customer Focus &`}</p>
                      <p className="aboutValue-the-board-plays">Quality</p>
                    </div>
                    <div className="aboutValue-text7">
                      The customer comes first; we will not compromise on the
                      standards of our products and services.
                    </div>
                  </div>
                </div>
              </div>
              <div className="aboutValue-column5" data-aos="fade-up" data-aos-duration="700">
                <div className="aboutValue-section-title1">
                <div className="aboutValue-icon-relume2" />
                  <img
                    className="aboutValue-run-x2c-rush-x2c-runner-x2c-icon5"
                    alt=""
                    src={AboutVector6}
                  />
                  <div className="aboutValue-section-title1">
                    <div className="aboutValue-heading5">
                      <p className="aboutValue-the-board-plays">{`Bias for Action &`}</p>
                      <p className="aboutValue-the-board-plays">Winning Spirit</p>
                    </div>
                    <div className="aboutValue-text7">
                      No stone will be left unturned in the pursuit of our
                      goals.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
