import React, { useEffect, useState } from 'react'
import "./aboutDirectors.css"
import DirectorIcon1 from "../../images/placeholder-image3@2x.png"
import DirectorIcon2 from "../../images/placeholder-image4@2x.png"
import Icon3 from "../../images/vector-06.svg"
import DirectorIcon3 from "../../images/placeholder-image6@2x.png"
import DirectorIcon4 from "../../images/placeholder-image7@2x.png"
import DirectorIcon5 from "../../images/placeholder-image8@2x.png"
import DirectorIcon6 from "../../images/placeholder-image9@2x.png"
import DirectorIcon7 from "../../images/placeholder-image10@2x.png"
import DirectorIcon8 from "../../images/placeholder-image11@2x.png"
import Aos from "aos";
import "aos/dist/aos.css";

export default function AboutDirectors() {

  const [activeDirector, setActiveDirector] = useState(null);

  const openModal = (directorName) => {
    setActiveDirector(directorName);
  };

  const closeModal = () => {
    setActiveDirector(null);
  };

  const handleOutsideClick = (event) => {
    if (event.target.className === "about-Directors-Story-modal") {
      closeModal();
    }
  };


  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  return (
    <div>
      <div className="dir-team-16">
            <div className="dir-section-title7">
              <div className="dir-content6" data-aos="fade-right" data-aos-duration="500">
                <div className="dir-heading">Board of Directors</div>
              </div>
            </div>
            <div className="dir-content30">
              <div className="dir-container6">
                <div className="dir-row1">
                  {/* Director 1 */}
                  <div className="dir-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon1}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">S H Amarasekera</div>
                          <div className="dir-job-title">
                            Independent Non-Executive Director/Chairman
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <div onClick={() => openModal('S H Amarasekera')} className="dir-read-more">Read More</div>
                        {/* Modal for S H Amarasekera */}
                        {activeDirector === 'S H Amarasekera' && (
                          <div className="about-Directors-Story-modal" onClick={handleOutsideClick}>
                            <div className="about-Directors-Story-modal-content">
                              <span className="about-Directors-Story-close" onClick={closeModal}>&times;</span>
                              <div>
                                <h1 className="about-Directors-Story-modal-content-heading">S H Amarasekera</h1>
                                <p className="about-Directors-Story-modal-content-paragraph">
                                  Mr. Harsha Amarasekera, President’s Counsel was appointed to the Board of
                                  CIC on the 28th October 2005. He was appointed as Acting Chairman on the
                                  01st of January 2014 and as Chairman on 23rd May 2014.
                                </p>
                                <p className="about-Directors-Story-modal-content-paragraph">Mr. Amarasekera is a leading luminary in the legal profession in Sri Lanka
                                  having a wide practice in the Original Courts as well as in the Appellate Courts.
                                  His fields of expertise include Commercial Law, Business Law, Securities Law,
                                  Banking Law and Intellectual Property Law.
                                </p>
                                <p>
                                  He also serves as the Chairman of several listed companies on the Colombo
                                  Stock Exchange including Sampath Bank PLC, Vallibel One PLC, Royal
                                  Ceramics Lanka PLC, Swisstek (Ceylon) PLC, Vallibel Power Erathna PLC and
                                  Swisstek Aluminium Ltd.
                                </p>
                                <p>
                                  He is also the Chairman of CIC Agri Businesses (Private) Ltd. He is an
                                  Independent Non-Executive Director of Expolanka Holdings PLC and Ambeon
                                  Capital PLC.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        <img className="dir-button-inner" alt="" src={Icon3} />
                      </div>
                    </div>
                  </div>

                   {/* Director 2 */}
                  <div className="dir-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon2}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">R S Captain</div>
                          <div className="dir-job-title">
                            Non Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <div onClick={() => openModal('R S Captain')} className="dir-read-more">Read More</div>
                        {/* Modal for R S Captain */}
                        {activeDirector === 'R S Captain' && (
                          <div className="about-Directors-Story-modal" onClick={handleOutsideClick}>
                            <div className="about-Directors-Story-modal-content">
                              <span className="about-Directors-Story-close" onClick={closeModal}>&times;</span>
                              <div>
                                <h1 className="about-Directors-Story-modal-content-heading">R S Captain</h1>
                                <p className="about-Directors-Story-modal-content-paragraph">
                                  Appointed to the Board on 10th March 2008. Mr. R. S. Captain is an
                                  entrepreneur and investor in Sri Lankan corporate sector, bringing with him
                                  a wealth of knowledge and over 16 years of business experience in a range
                                  of manufacturing sectors. His current business interests range from paints,
                                  garments, industrial gloves, cutting and polishing diamonds, plastics, and other
                                  packing material. He is the Co-Founder of Asia Stockbrokers, Asia Capital,

                                  Dutch Lanka Trailers, Asia Siyaka and Asian Alliance. He has served as Non-
                                  Executive Director of Hatton National Bank PLC, John Keells Holdings PLC and

                                  many other unlisted Companies. Mr. Captain was educated at the University of
                                  Miami, Florida, USA.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        <img className="dir-button-inner" alt="" src={Icon3} />
                      </div>
                    </div>
                  </div>
                </div>

                 <div className="dir-row1">
                  <div className="dir-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon3}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">S M Enderby</div>
                          <div className="dir-job-title">
                            Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <div onClick={() => openModal('S M Enderby')} className="dir-read-more">Read More</div>
                          {activeDirector === 'S M Enderby' && (
                            <div className="about-Directors-Story-modal" onClick={handleOutsideClick}>
                              <div className="about-Directors-Story-modal-content">
                                <span className="about-Directors-Story-close" onClick={closeModal}>&times;</span>
                                  <div>
                                    <h1 className="about-Directors-Story-modal-content-heading">S M Enderby</h1>

                                    <p className="about-Directors-Story-modal-content-paragraph">Joined CIC Board on 11th April 2013. Prior to that he has had a successful
                                      track record in private equity with Actis, a leading global emerging markets fund.
                                      Mr Enderby has led many of the most successful private equity transactions in
                                      Sri Lanka.</p>          
                                    <p className="about-Directors-Story-modal-content-paragraph">Post Actis, Mr. Enderby joined Hemas Holdings PLC in 2013 where he was
                                      Group Chief Executive Officer playing a pivotal role in transforming the Group
                                      into one of Sri Lanka’s largest Healthcare and Consumer focused businesses.</p>
                                    <p className="about-Directors-Story-modal-content-paragraph">Following his retirement in 2020 Mr. Enderby continues to invest in Sri Lanka
                                      with a particular focus on the consumer and education sectors through his role
                                      as Chairman of Ironwood Capital Partners and as a Director and advisor to a
                                      number of high growth businesses.</p>
                                    <p>
                                      During his more than 35 years of corporate and investing experience, he has
                                      served on the Boards of many leading companies in Sri Lanka and India. He is
                                      a Fellow Member of the Chartered Institute of Management Accountants, holds
                                      a Degree in Economics and Accounting from Queen’s University Belfast and a
                                      Master’s Degree in Development Studies from the University of Melbourne.
                                    </p>
                                  </div>
                              </div>
                            </div>
                          )}
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="dir-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon4}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">M P Jayawardena</div>
                          <div className="dir-job-title">
                            Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <div onClick={() => openModal('M P Jayawardena')} className="dir-read-more">Read More</div>
                          {activeDirector === 'M P Jayawardena' && (
                            <div className="about-Directors-Story-modal" onClick={handleOutsideClick}>
                              <div className="about-Directors-Story-modal-content">
                                <span className="about-Directors-Story-close" onClick={closeModal}>&times;</span>
                                  <div>
                                    <h1 className="about-Directors-Story-modal-content-heading">M P Jayawardena</h1>

                                    <p className="about-Directors-Story-modal-content-paragraph">Appointed Alternate Director to CIC Nominee Director on 21st May 2002,
                                      thereafter as a Director on 25th October 2008. He was a Past Deputy
                                      Chairman of Commercial Bank of Ceylon PLC. He is a Fellow Member of the
                                      Institute of Chartered Accountants of Sri Lanka. He is also the Chairman of
                                      Commercial Insurance Brokers (Private) Limited and serves on the Boards
                                      of many other private companies. He served at Zambia Consolidated Copper
                                      Mines in Africa for over 13 years in various senior positions including Head of
                                      Treasury, managing a loan portfolio in excess of US$ 3Bn. He was the Past
                                      President of The Sri Lanka Institute of Directors, Member of the Monitory Policy
                                      Consultation Committee of the Central Bank and the Chairman of the Ceylon
                                      National Chamber of Industries.</p>
                                  </div>
                              </div>
                            </div>
                          )}
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dir-row1">
                  <div className="dir-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon5}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">P R Saldin</div>
                          <div className="dir-job-title">
                            Non-Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <div onClick={() => openModal('P R Saldin')} className="dir-read-more">Read More</div>
                          {activeDirector === 'P R Saldin' && (
                            <div className="about-Directors-Story-modal" onClick={handleOutsideClick}>
                              <div className="about-Directors-Story-modal-content">
                                <span className="about-Directors-Story-close" onClick={closeModal}>&times;</span>
                                  <div>
                                    <h1 className="about-Directors-Story-modal-content-heading">P R Saldin</h1>

                                    <p className="about-Directors-Story-modal-content-paragraph">First appointed to the Board of Directors in 1995 and served as Commercial
                                      Director and Group Finance Director till 2005. During this period, he also served
                                      on the Board of Directors in many of the Subsidiaries and Associate Companies
                                      within the Group. On leaving the CIC Group he functioned as Country Controller
                                      and Group Finance Director for Shell Sri Lanka and subsequently as Group
                                      Chief Operating Officer of Browns Group of Companies and Managing Director
                                      of Browns Investment PLC.</p>
                                    <p className="about-Directors-Story-modal-content-paragraph">He is currently employed as Director of Paints & General Industries Limited
                                      and Director/Chief Executive Officer of Polypak Secco Limited. He was
                                      re-appointed to the Board of CIC Holdings PLC on 1st July 2016. He also
                                      functions as Chairman of Chemanex PLC and serves on the Board of Directors
                                      of Akzo Nobel Paints Lanka (Private) Limited, Link Natural Products (Private)
                                      Limited, CIC Agri Businesses (Pvt) Ltd. He is the Chairman of CISCO Specialty
                                      Packaging (Private) Limited. He also is a Director of Hatton National Bank PLC,
                                      HNB Assurance PLC and Chairman of Sithma Development (Private) Limited
                                      and serves as a Trustee of the HNB Sustainability Foundation.</p>
                                    <p className="about-Directors-Story-modal-content-paragraph">Rimoe Saldin is a Fellow of the Institute of Chartered Accountants of Sri Lanka.
                                      An associate of Institute of Chartered Accountants of England and Wales. He
                                      is also a Fellow of the Chartered Institute of Management Accountants in UK
                                      and a Certified Management Accountant, Australia. He is an alumni of the Asian
                                      Institute of Management Manila. He has over 26 years of top management level
                                      experience in the areas of Finance, Human Resource Development, General
                                      Management and Operations.</p>
                                  </div>
                              </div>
                            </div>
                          )}
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="dir-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon6}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-name7">D T S H Mudalige</div>
                          <div className="dir-job-title">
                            Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <div onClick={() => openModal('D T S H Mudalige')} className="dir-read-more">Read More</div>
                          {activeDirector === 'D T S H Mudalige' && (
                            <div className="about-Directors-Story-modal" onClick={handleOutsideClick}>
                              <div className="about-Directors-Story-modal-content">
                                <span className="about-Directors-Story-close" onClick={closeModal}>&times;</span>
                                  <div>
                                    <h1 className="about-Directors-Story-modal-content-heading">D T S H Mudalige</h1>

                                    <p className="about-Directors-Story-modal-content-paragraph">Appointed to the Board on 16th October 2020, Mr. Sujeewa Mudalige
                                      counts over 30 years of experience as a Chartered Accountant. He is also a
                                      fellow member of CIMA (UK), ACCA (UK) and CPA (Australia). He has vast
                                      experience as the Audit Committee Chair in both public and private sector
                                      organizations. Mr. Mudalige recently retired as the Managing Partner of
                                      PricewaterhouseCoopers, Sri Lanka and currently serves as an Independent
                                      Non-Executive Director at CIC Feeds Group and Link Natural Products (Private)
                                      Limited. He is the Non-Executive Chair of Mercantile Services Provident
                                      Society (MSPS). He is also an Independent Non-Executive Director at National
                                      Development Bank PLC, NSBM Green University and Mahindra Ideal Finance
                                      Ltd.</p>
                                              
                                    <p className="about-Directors-Story-modal-content-paragraph">He is a past President of ICASL and has been a member of the Council
                                      of ICASL and of the governing board of CIMA UK- Sri Lanka Division, a
                                      Commission member of the Securities and Exchange Commission of Sri Lanka
                                      and held several such other positions locally and globally during the span of
                                      his career. He was an Independent Non-Executive Director of Hatton National
                                      Bank PLC from 2012 to 2019.</p>
                                   
                                  </div>
                              </div>
                            </div>
                          )}
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dir-row1">
                  <div className="dir-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon7}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">J R Gunaratne</div>
                          <div className="dir-job-title">
                            Independent Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <div onClick={() => openModal('J R Gunaratne')} className="dir-read-more">Read More</div>
                          {activeDirector === 'J R Gunaratne' && (
                            <div className="about-Directors-Story-modal" onClick={handleOutsideClick}>
                              <div className="about-Directors-Story-modal-content">
                                <span className="about-Directors-Story-close" onClick={closeModal}>&times;</span>
                                  <div>
                                    <h1 className="about-Directors-Story-modal-content-heading">J R Gunaratne</h1>

                                    <p className="about-Directors-Story-modal-content-paragraph">Appointed to the Board on 06th January 2021, Mr. J. R. Gunaratne counts
                                      over 40 years of experience in Strategic Planning, Production & Distribution,
                                      Industrial Relations and Change Management in the Food & Beverage,
                                      Plantations and Leisure Sectors.</p>
                                              
                                    <p className="about-Directors-Story-modal-content-paragraph">During his career at John Keells Holdings PLC, he has held Directorships in
                                      several listed and private companies of the John Keells Group. He has been a
                                      Member of the Food Advisory Council of the Ministry of Health and a Member
                                      of the Council for Hotel & Tourism of the Employer’s Federation of Ceylon. He
                                      was the Founder Chairman of the Beverage Association of Sri Lanka.</p>
                                            
                                  </div>
                              </div>
                            </div>
                          )}
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="dir-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="dir-placeholder-image-icon3"
                      alt=""
                      src={DirectorIcon8}
                    />
                    <div className="dir-column12">
                      <div className="dir-content31">
                        <div className="dir-title">
                          <div className="dir-date">K D Senewiratne</div>
                          <div className="dir-job-title">
                            Independent, Non-Executive Director
                          </div>
                        </div>
                      </div>
                      <div className="dir-button28">
                        <div className="dir-button-child" />
                        <div onClick={() => openModal('K D Senewiratne')} className="dir-read-more">Read More</div>
                          {activeDirector === 'K D Senewiratne' && (
                            <div className="about-Directors-Story-modal" onClick={handleOutsideClick}>
                              <div className="about-Directors-Story-modal-content">
                                <span className="about-Directors-Story-close" onClick={closeModal}>&times;</span>
                                  <div>
                                    <h1 className="about-Directors-Story-modal-content-heading">K D Senewiratne</h1>

                                    <p className="about-Directors-Story-modal-content-paragraph">Appointed to the Board on 01st January 2023. Ms. Kshenuka Senewiratne
                                      is the first female Permanent Representative to the United Nations (UN) in
                                      New York and High Commissioner to the United Kingdom (UK) and also the
                                      Secretary of the Ministry of Foreign Affairs.</p>
                                              
                                    <p className="about-Directors-Story-modal-content-paragraph">Ms. Senewiratne a seasoned diplomat, who retired from the Public Service
                                      in 2020, counts a 35-year track record with the Sri Lanka foreign service,
                                      having held prestigious postings as Sri Lanka’s Head of Mission to the UN in
                                      Geneva and New York, UK and Thailand. She has extensive expertise in the
                                      North American, European, and Asian regions in a multitude of strategies to
                                      enhance bilateral and multilateral relations, especially through political and
                                      economic cooperation in the global context, and also ensuring the overall
                                      administrative effectiveness of the Sri Lanka Missions overseas and the Ministry
                                      of Foreign Affairs where she served. She also worked in an advisory capacity on
                                      international media, and is currently Sri Lanka’s High Commissioner to India.</p>
                                     
                                  </div>
                              </div>
                            </div>
                          )}
                        <img
                          className="dir-button-inner"
                          alt=""
                          src={Icon3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
