import React, { useEffect} from 'react'
import "./aboutManagement.css"
import ManagementIcon1 from "../../images/aroshan1.png"
import ManagementIcon2 from "../../images/devapriya2.png"
import ManagementIcon3 from "../../images/waruna3.png"
import ManagementIcon4 from "../../images/jayantha4.png"
import ManagementIcon5 from "../../images/erandi5.png"
import ManagementIcon6 from "../../images/6aruna.png"
import ManagementIcon7 from "../../images/7wiraj.png"
import ManagementIcon8 from "../../images/8prasadi.png"
import ManagementIcon9 from "../../images/9ramani.png"
import ManagementIcon10 from "../../images/10ajith.png"
import ManagementIcon11 from "../../images/11roshanie.png"
import ManagementIcon12 from "../../images/12prasad.png"
import ManagementIcon13 from "../../images/13thushara.png"
import ManagementIcon14 from "../../images/14hasitha.png"
import ManagementIcon15 from "../../images/15mahesh.png"
import ManagementIcon16 from "../../images/16marlon.png"
import ManagementIcon17 from "../../images/17anjana.png"
import ManagementIcon18 from "../../images/18vidura.png"
import ManagementIcon19 from "../../images/19ranjith.png"
import ManagementIcon20 from "../../images/20thilini.png"
import ManagementIcon21 from "../../images/21nadeera.png"
import ManagementIcon22 from "../../images/22poov.png"
import ManagementIcon23 from "../../images/23damo.png"
import Aos from "aos";
import "aos/dist/aos.css";

export default function AboutManagement() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  return (
    <div>
      <div className="mgt-team-16">
            <div className="mgt-section-title7">
              <div className="mgt-content6" data-aos="fade-right" data-aos-duration="500">
                <div className="mgt-heading">Management Team</div>
              </div>
            </div>
            <div className="mgt-content30">
              <div className="mgt-container6">
                <div className="mgt-row1">
                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon1}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. AROSHAN SERESINHE</div>
                          <div className="mgt-job-title">
                            Group Chief Executive Officer
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                   <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon2}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">DR. DEVAPRIYA NUGAWELA</div>
                          <div className="mgt-job-title">
                            Chairman - Link Natural Products (Private) Limited
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon3}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. WARUNA MADAWANARACHCHI</div>
                          <div className="mgt-job-title">
                            Managing Director - Agri Cluster
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon4}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. JAYANTHA RAJAPAKSE</div>
                          <div className="mgt-job-title">
                          Chairman/Managing Director Unipower (Private) Limited
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                 <div className="mgt-row1">
                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon5}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MS. ERANDI WICKRAMAARACHCHI</div>
                          <div className="mgt-job-title">
                            Group Chief Financial Officer
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon6}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. ARUNA JAYASEKERA</div>
                          <div className="mgt-job-title">
                            Group Head of Human Resources
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon7}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. VIRAJ MANATUNGA</div>
                          <div className="mgt-job-title">
                          Group Chief Strategy Officer / Divisional Director - Pharma
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon8}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MS. PRASADI SAMARAKOON</div>
                          <div className="mgt-job-title">
                          Head of Group Treasury/Divisional Director - Group Finance
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mgt-row1">
                    <div className="mgt-card3">
                        <img
                        data-aos="fade-right" data-aos-duration="500"
                        className="mgt-placeholder-image-icon3"
                        alt=""
                        src={ManagementIcon9}
                        />
                        <div className="mgt-column12">
                        <div className="mgt-content31">
                            <div className="mgt-title">
                            <div className="mgt-date">MS. RAMANI SAMARASUNDERA</div>
                            <div className="mgt-job-title">
                              Group Chief Marketing Officer
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon10}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. AJITH WEERASINGHE</div>
                          <div className="mgt-job-title">
                            Chief Executive Officer - CIC Feeds Group
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div className="mgt-card3">
                        <img
                        data-aos="fade-right" data-aos-duration="500"
                        className="mgt-placeholder-image-icon3"
                        alt=""
                        src={ManagementIcon11}
                        />
                        <div className="mgt-column12">
                        <div className="mgt-content31">
                            <div className="mgt-title">
                            <div className="mgt-date">MS. ROSHANIE JAYASUNDERA MORAES</div>
                            <div className="mgt-job-title">
                            Chief Executive Officer Link Natural Products (Private) Limited
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon12}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. PRASAD WEGIRIYA</div>
                          <div className="mgt-job-title">
                          Director/Chief Executive Officer - CISCO Speciality Packaging (Private) Limited
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mgt-row1">
                 <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon13}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. THUSHARA YATIGAMMANA</div>
                          <div className="mgt-job-title">
                          Chief Operating Officer Chemanex PLC / Divisional Director -Industrial Solutions
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon14}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. HASITHA AHANGAMA</div>
                          <div className="mgt-job-title">
                          Divisional Director - Healthcare - Medical Devices and Diagnostics
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon15}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. MAHESH WANNINAYAKE</div>
                          <div className="mgt-job-title">
                            Divisional Director - Crop Solutions
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon16}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. MARLON FERNANDOPULLE</div>
                          <div className="mgt-job-title">
                          Divisional Director- Human Resources & Corporate Affairs
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mgt-row1">
                 <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon17}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. ANJANA LEELARATHNE</div>
                          <div className="mgt-job-title">
                            Deputy Managing Director - Agri Cluster
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon18}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. VIDURA GAMAGE</div>
                          <div className="mgt-job-title">
                          Director/Chief Operating Officer CIC CropGuard (Private) Limited
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon19}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. RANJITH BANDARA</div>
                          <div className="mgt-job-title">
                          Chief Operating Officer - Unipower (Private) Limited
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon20}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MS. THILINI WEGODAPOLA</div>
                          <div className="mgt-job-title">
                            General Manager - Group Legal
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="mgt-row1">

                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon21}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. NADEERA WIJENAYAKE</div>
                          <div className="mgt-job-title">
                            Divisional Director - Group IT
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon22}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MR. POOVENDRAN SIVATHAVENDRAN</div>
                          <div className="mgt-job-title">
                            General Manager - RPC & PVA Plant
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mgt-card3">
                    <img
                      data-aos="fade-right" data-aos-duration="500"
                      className="mgt-placeholder-image-icon3"
                      alt=""
                      src={ManagementIcon23}
                    />
                    <div className="mgt-column12">
                      <div className="mgt-content31">
                        <div className="mgt-title">
                          <div className="mgt-date">MS. DAMODARA WICKRAMANAYAKE</div>
                          <div className="mgt-job-title">
                          Chief Financial Officer - CIC Capital / Chemanex PLC
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
    </div>
  )
}
