import React from 'react'
import "./aboutBrandStory.css"
import BrandStoryImage from "../../images/brandStoryImage.jpg"

export default function AboutBrandStory() {
  return (
    <div>
      <div className="brandStory-layout-91">
            <div className="brandStory-content2">
              <div className="brandStory-column11">
                <div className="brandStory-heading12">Corporate Brand Story</div>
              </div>
              <div className="brandStory-column12">
                <div className="brandStory-date">Brand Purpose</div>
                <div className="brandStory-text-suspendisse-varius1">
                  We nurture life by seeking to have a positive impact on our
                  society, our people and our businesses. We embrace science and
                  modern technology to provide products and services of superior
                  quality and value that nurture life in an inclusive, caring
                  and sustainable manner.
                </div>
              </div>
            </div>
            <img
              className="brandStory-placeholder-lightbox-icon"
              alt="loading"
              src={BrandStoryImage}
            />
          </div>
    </div>
  )
}
